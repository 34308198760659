import * as i0 from '@angular/core';
import { Directive, Inject, NgModule } from '@angular/core';
import { TuiPanService } from '@taiga-ui/cdk/services';
import * as i1 from 'rxjs';
class TuiPanDirective {
  constructor(tuiPan) {
    this.tuiPan = tuiPan;
  }
}
TuiPanDirective.ɵfac = function TuiPanDirective_Factory(t) {
  return new (t || TuiPanDirective)(i0.ɵɵdirectiveInject(TuiPanService));
};
TuiPanDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiPanDirective,
  selectors: [["", "tuiPan", ""]],
  outputs: {
    tuiPan: "tuiPan"
  },
  features: [i0.ɵɵProvidersFeature([TuiPanService])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPanDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiPan]',
      outputs: ['tuiPan'],
      providers: [TuiPanService]
    }]
  }], function () {
    return [{
      type: i1.Observable,
      decorators: [{
        type: Inject,
        args: [TuiPanService]
      }]
    }];
  }, null);
})();

/**
 * @experimental
 */
class TuiPanModule {}
TuiPanModule.ɵfac = function TuiPanModule_Factory(t) {
  return new (t || TuiPanModule)();
};
TuiPanModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiPanModule
});
TuiPanModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPanModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiPanDirective],
      exports: [TuiPanDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiPanDirective, TuiPanModule };
