import * as i0 from '@angular/core';
import { Directive, Inject, NgModule } from '@angular/core';
import { TuiZoomService } from '@taiga-ui/cdk/services';
import * as i1 from 'rxjs';
class TuiZoomDirective {
  constructor(tuiZoom) {
    this.tuiZoom = tuiZoom;
  }
}
TuiZoomDirective.ɵfac = function TuiZoomDirective_Factory(t) {
  return new (t || TuiZoomDirective)(i0.ɵɵdirectiveInject(TuiZoomService));
};
TuiZoomDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiZoomDirective,
  selectors: [["", "tuiZoom", ""]],
  hostVars: 2,
  hostBindings: function TuiZoomDirective_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵstyleProp("touch-action", "none");
    }
  },
  outputs: {
    tuiZoom: "tuiZoom"
  },
  features: [i0.ɵɵProvidersFeature([TuiZoomService])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiZoomDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiZoom]',
      outputs: ['tuiZoom'],
      providers: [TuiZoomService],
      host: {
        '[style.touch-action]': '"none"'
      }
    }]
  }], function () {
    return [{
      type: i1.Observable,
      decorators: [{
        type: Inject,
        args: [TuiZoomService]
      }]
    }];
  }, null);
})();

/**
 * @experimental
 */
class TuiZoomModule {}
TuiZoomModule.ɵfac = function TuiZoomModule_Factory(t) {
  return new (t || TuiZoomModule)();
};
TuiZoomModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiZoomModule
});
TuiZoomModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiZoomModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiZoomDirective],
      exports: [TuiZoomDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiZoomDirective, TuiZoomModule };
