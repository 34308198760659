import * as i0 from '@angular/core';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, Inject, HostListener, NgModule, Injectable } from '@angular/core';
import { tuiSlideInTop } from '@taiga-ui/core';
import * as i1 from '@tinkoff/ng-polymorpheus';
import { POLYMORPHEUS_CONTEXT, PolymorpheusModule, PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { AbstractTuiDialogService } from '@taiga-ui/cdk';
function TuiPreviewDialogComponent_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const text_r1 = ctx.polymorpheusOutlet;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", text_r1, " ");
  }
}
class TuiPreviewDialogComponent {
  constructor(context) {
    this.context = context;
  }
  onKeyDownEsc() {
    this.context.$implicit.complete();
  }
}
TuiPreviewDialogComponent.ɵfac = function TuiPreviewDialogComponent_Factory(t) {
  return new (t || TuiPreviewDialogComponent)(i0.ɵɵdirectiveInject(POLYMORPHEUS_CONTEXT));
};
TuiPreviewDialogComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiPreviewDialogComponent,
  selectors: [["tui-preview-dialog"]],
  hostBindings: function TuiPreviewDialogComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("keydown.esc", function TuiPreviewDialogComponent_keydown_esc_HostBindingHandler() {
        return ctx.onKeyDownEsc();
      }, false, i0.ɵɵresolveDocument);
    }
  },
  decls: 2,
  vars: 3,
  consts: [[1, "t-dialog-content"], [4, "polymorpheusOutlet", "polymorpheusOutletContext"]],
  template: function TuiPreviewDialogComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵtemplate(1, TuiPreviewDialogComponent_ng_container_1_Template, 2, 1, "ng-container", 1);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("@tuiSlideInTop", undefined);
      i0.ɵɵadvance();
      i0.ɵɵproperty("polymorpheusOutlet", ctx.context.content)("polymorpheusOutletContext", ctx.context);
    }
  },
  dependencies: [i1.PolymorpheusOutletDirective],
  styles: [".t-dialog-content{position:fixed;width:100%;height:100%}[tuiWrapper][data-appearance=preview-action]{background:rgba(104,104,104,.96);color:var(--tui-text-01-night)}@media (hover: hover){[tuiWrapper][data-appearance=preview-action]:hover:not(._no-hover),[tuiWrapper][data-appearance=preview-action][data-state=hover]{background:rgba(159,159,159,.86)}}[tuiWrapper][data-appearance=preview-action]:active:not(._no-active),[tuiWrapper][data-appearance=preview-action][data-state=active],[tuiWrapper][data-appearance=preview-action][data-state=active]:hover{background:rgba(159,159,159,.75)}\n"],
  encapsulation: 2,
  data: {
    animation: [tuiSlideInTop]
  },
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPreviewDialogComponent, [{
    type: Component,
    args: [{
      selector: 'tui-preview-dialog',
      templateUrl: './preview-dialog.template.html',
      styleUrls: ['./preview-dialog.style.less'],
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      animations: [tuiSlideInTop]
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [POLYMORPHEUS_CONTEXT]
      }]
    }];
  }, {
    onKeyDownEsc: [{
      type: HostListener,
      args: ['document:keydown.esc']
    }]
  });
})();
class TuiPreviewDialogModule {}
TuiPreviewDialogModule.ɵfac = function TuiPreviewDialogModule_Factory(t) {
  return new (t || TuiPreviewDialogModule)();
};
TuiPreviewDialogModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiPreviewDialogModule
});
TuiPreviewDialogModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[PolymorpheusModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPreviewDialogModule, [{
    type: NgModule,
    args: [{
      imports: [PolymorpheusModule],
      declarations: [TuiPreviewDialogComponent],
      exports: [TuiPreviewDialogComponent]
    }]
  }], null, null);
})();
class TuiPreviewDialogService extends AbstractTuiDialogService {
  constructor() {
    super(...arguments);
    this.defaultOptions = {};
    this.component = new PolymorpheusComponent(TuiPreviewDialogComponent);
  }
}
TuiPreviewDialogService.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiPreviewDialogService_BaseFactory;
  return function TuiPreviewDialogService_Factory(t) {
    return (ɵTuiPreviewDialogService_BaseFactory || (ɵTuiPreviewDialogService_BaseFactory = i0.ɵɵgetInheritedFactory(TuiPreviewDialogService)))(t || TuiPreviewDialogService);
  };
})();
TuiPreviewDialogService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TuiPreviewDialogService,
  factory: TuiPreviewDialogService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPreviewDialogService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiPreviewDialogComponent, TuiPreviewDialogModule, TuiPreviewDialogService };
