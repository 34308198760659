import { tuiCreateTokenFromFactory, tuiCreateToken, tuiProvideOptions } from '@taiga-ui/cdk';
import { tuiExtractI18n } from '@taiga-ui/i18n';

/**
 * tui-preview i18n
 */
const TUI_PREVIEW_TEXTS = tuiCreateTokenFromFactory(tuiExtractI18n('previewTexts'));
/**
 * tui-preview zoom i18n
 */
const TUI_PREVIEW_ZOOM_TEXTS = tuiCreateTokenFromFactory(tuiExtractI18n('zoomTexts'));

const TUI_PREVIEW_ICONS_DEFAULT = {
    rotate: 'tuiIconRotate',
    prev: 'tuiIconArrowLeft',
    next: 'tuiIconArrowRight',
    zoomIn: 'tuiIconPlus',
    zoomOut: 'tuiIconMinus',
    zoomReset: 'tuiIconMinimize',
};
const TUI_PREVIEW_ICONS = tuiCreateToken(TUI_PREVIEW_ICONS_DEFAULT);
function tuiPreviewIconsProvider(icons) {
    return tuiProvideOptions(TUI_PREVIEW_ICONS, icons, TUI_PREVIEW_ICONS_DEFAULT);
}

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_PREVIEW_ICONS, TUI_PREVIEW_ICONS_DEFAULT, TUI_PREVIEW_TEXTS, TUI_PREVIEW_ZOOM_TEXTS, tuiPreviewIconsProvider };

