import * as i0 from '@angular/core';
import { Directive, EventEmitter, Component, ChangeDetectionStrategy, Inject, Input, Output, HostListener, ElementRef, NgModule } from '@angular/core';
import { TUI_PREVIEW_ICONS, TUI_PREVIEW_ZOOM_TEXTS, TUI_PREVIEW_TEXTS } from '@taiga-ui/addon-preview/tokens';
import * as i4$1 from '@taiga-ui/cdk';
import { tuiClamp, ALWAYS_FALSE_HANDLER, tuiDragAndDropFrom, TuiDragStage, tuiTypedFromEvent, tuiPx, tuiRound, TuiDragModule, TuiResizeModule, TuiPanModule, TuiZoomModule, tuiAsDialog } from '@taiga-ui/cdk';
import * as i2 from '@taiga-ui/kit';
import { TUI_PAGINATION_TEXTS, TuiSliderModule } from '@taiga-ui/kit';
import * as i1 from '@taiga-ui/core';
import { tuiButtonOptionsProvider, tuiSlideInTop, TuiButtonModule, TuiHintModule, TuiSvgModule, TuiLoaderModule } from '@taiga-ui/core';
import * as i3 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i4 from 'rxjs';
import { merge, of, timer, BehaviorSubject, combineLatest } from 'rxjs';
import { switchMap, map, startWith } from 'rxjs/operators';
import * as i5 from '@angular/forms';
import { FormsModule } from '@angular/forms';
import * as i5$1 from '@ng-web-apis/mutation-observer';
import { MutationObserverModule } from '@ng-web-apis/mutation-observer';
import { TuiPreviewDialogService } from '@taiga-ui/addon-preview/components/preview-dialog';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
function TuiPreviewPaginationComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "button", 1);
    i0.ɵɵlistener("click", function TuiPreviewPaginationComponent_ng_container_0_Template_button_click_1_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onArrowClick(-1));
    });
    i0.ɵɵelementEnd();
    i0.ɵɵtext(2);
    i0.ɵɵelementStart(3, "button", 2);
    i0.ɵɵlistener("click", function TuiPreviewPaginationComponent_ng_container_0_Template_button_click_3_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onArrowClick(1));
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const texts_r3 = ctx.ngIf;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("disabled", ctx_r1.leftButtonDisabled)("icon", ctx_r1.icons.prev)("title", texts_r3[0]);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate2(" ", ctx_r1.index + 1, "/", ctx_r1.length, " ");
    i0.ɵɵadvance();
    i0.ɵɵproperty("disabled", ctx_r1.rightButtonDisabled)("icon", ctx_r1.icons.next)("title", texts_r3[1]);
  }
}
function TuiPreviewZoomComponent_ng_container_0_ng_template_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "percent");
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(2, 3, i0.ɵɵpipeBind1(1, 1, ctx_r1.valueChange) || ctx_r1.value), " ");
  }
}
function TuiPreviewZoomComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "section", 2)(2, "button", 3);
    i0.ɵɵlistener("click", function TuiPreviewZoomComponent_ng_container_0_Template_button_click_2_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onMinus());
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "label", 4);
    i0.ɵɵelement(4, "div", 5);
    i0.ɵɵpipe(5, "async");
    i0.ɵɵtemplate(6, TuiPreviewZoomComponent_ng_container_0_ng_template_6_Template, 3, 5, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementStart(8, "input", 6);
    i0.ɵɵlistener("ngModelChange", function TuiPreviewZoomComponent_ng_container_0_Template_input_ngModelChange_8_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onModelChange($event));
    });
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(9, "button", 7);
    i0.ɵɵlistener("click", function TuiPreviewZoomComponent_ng_container_0_Template_button_click_9_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onPlus());
    });
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(10, "button", 8);
    i0.ɵɵlistener("click", function TuiPreviewZoomComponent_ng_container_0_Template_button_click_10_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onReset());
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const texts_r3 = ctx.ngIf;
    const hint_r4 = i0.ɵɵreference(7);
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("disabled", ctx_r1.leftButtonDisabled)("icon", ctx_r1.icons.zoomOut)("title", texts_r3.zoomOut);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("tuiHint", hint_r4)("tuiHintManual", !!i0.ɵɵpipeBind1(5, 15, ctx_r1.hintShow$));
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("max", ctx_r1.max)("min", ctx_r1.min)("ngModel", ctx_r1.value);
    i0.ɵɵadvance();
    i0.ɵɵproperty("disabled", ctx_r1.rightButtonDisabled)("icon", ctx_r1.icons.zoomIn)("title", texts_r3.zoomIn);
    i0.ɵɵadvance();
    i0.ɵɵclassProp("t-invisible", !ctx_r1.collapseVisible);
    i0.ɵɵproperty("icon", ctx_r1.icons.zoomReset)("tuiHint", texts_r3.reset);
  }
}
const _c0 = ["*", [["tui-preview-title"]], [["tui-preview-pagination"]], [["", "tuiPreviewAction", ""]]];
const _c1 = ["*", "tui-preview-title", "tui-preview-pagination", "[tuiPreviewAction]"];
function TuiPreviewComponent_ng_container_0_button_14_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 9);
    i0.ɵɵlistener("click", function TuiPreviewComponent_ng_container_0_button_14_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.rotate());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const texts_r5 = i0.ɵɵnextContext().ngIf;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("icon", ctx_r1.icons.rotate)("tuiHint", texts_r5.rotate);
  }
}
function TuiPreviewComponent_ng_container_0_tui_preview_zoom_15_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "tui-preview-zoom", 10);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵlistener("reset", function TuiPreviewComponent_ng_container_0_tui_preview_zoom_15_Template_tui_preview_zoom_reset_0_listener() {
      i0.ɵɵrestoreView(_r6);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.reset());
    })("valueChange", function TuiPreviewComponent_ng_container_0_tui_preview_zoom_15_Template_tui_preview_zoom_valueChange_0_listener($event) {
      i0.ɵɵrestoreView(_r6);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.setZoom($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("min", ctx_r1.minZoom)("value", i0.ɵɵpipeBind1(1, 2, ctx_r1.zoom$) || 1);
  }
}
function TuiPreviewComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "section", 2, 0);
    i0.ɵɵpipe(3, "async");
    i0.ɵɵpipe(4, "async");
    i0.ɵɵpipe(5, "async");
    i0.ɵɵlistener("tuiPan", function TuiPreviewComponent_ng_container_0_Template_section_tuiPan_1_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onPan($event));
    })("tuiResize", function TuiPreviewComponent_ng_container_0_Template_section_tuiResize_1_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onResize($event));
    })("tuiZoom", function TuiPreviewComponent_ng_container_0_Template_section_tuiZoom_1_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onZoom($event));
    })("waMutationObserver", function TuiPreviewComponent_ng_container_0_Template_section_waMutationObserver_1_listener() {
      i0.ɵɵrestoreView(_r1);
      const contentWrapper_r3 = i0.ɵɵreference(2);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onMutation(contentWrapper_r3));
    });
    i0.ɵɵprojection(6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "header", 3)(8, "div", 4);
    i0.ɵɵprojection(9, 1);
    i0.ɵɵelementEnd();
    i0.ɵɵprojection(10, 2);
    i0.ɵɵelementStart(11, "div", 5);
    i0.ɵɵprojection(12, 3);
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(13, "footer", 6);
    i0.ɵɵtemplate(14, TuiPreviewComponent_ng_container_0_button_14_Template, 1, 2, "button", 7)(15, TuiPreviewComponent_ng_container_0_tui_preview_zoom_15_Template, 2, 4, "tui-preview-zoom", 8);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵstyleProp("cursor", i0.ɵɵpipeBind1(3, 10, ctx_r1.cursor$))("transform", i0.ɵɵpipeBind1(4, 12, ctx_r1.wrapperTransform$));
    i0.ɵɵclassProp("t-not-interactive-content", ctx_r1.zoomable)("t-transitive", i0.ɵɵpipeBind1(5, 14, ctx_r1.transitioned$));
    i0.ɵɵadvance(13);
    i0.ɵɵproperty("ngIf", ctx_r1.rotatable);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.zoomable);
  }
}
const _c2 = ["*"];
class TuiPreviewActionDirective {}
TuiPreviewActionDirective.ɵfac = function TuiPreviewActionDirective_Factory(t) {
  return new (t || TuiPreviewActionDirective)();
};
TuiPreviewActionDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiPreviewActionDirective,
  selectors: [["", "tuiPreviewAction", ""]],
  features: [i0.ɵɵProvidersFeature([tuiButtonOptionsProvider({
    appearance: 'preview-action',
    shape: 'rounded',
    size: 's'
  })])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPreviewActionDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiPreviewAction]',
      providers: [tuiButtonOptionsProvider({
        appearance: 'preview-action',
        shape: 'rounded',
        size: 's'
      })]
    }]
  }], null, null);
})();
class TuiPreviewPaginationComponent {
  constructor(icons, texts$) {
    this.icons = icons;
    this.texts$ = texts$;
    this.length = 1;
    this.index = 0;
    this.indexChange = new EventEmitter();
  }
  get leftButtonDisabled() {
    return this.index === 0;
  }
  get rightButtonDisabled() {
    return this.index === this.length - 1;
  }
  onArrowClick(step) {
    this.updateIndex(tuiClamp(this.index + step, 0, this.length - 1));
  }
  updateIndex(index) {
    if (this.index === index) {
      return;
    }
    this.index = index;
    this.indexChange.emit(index);
  }
}
TuiPreviewPaginationComponent.ɵfac = function TuiPreviewPaginationComponent_Factory(t) {
  return new (t || TuiPreviewPaginationComponent)(i0.ɵɵdirectiveInject(TUI_PREVIEW_ICONS), i0.ɵɵdirectiveInject(TUI_PAGINATION_TEXTS));
};
TuiPreviewPaginationComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiPreviewPaginationComponent,
  selectors: [["tui-preview-pagination"]],
  hostBindings: function TuiPreviewPaginationComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("keydown.arrowRight.prevent", function TuiPreviewPaginationComponent_keydown_arrowRight_prevent_HostBindingHandler() {
        return ctx.onArrowClick(1);
      }, false, i0.ɵɵresolveDocument)("keydown.arrowLeft.prevent", function TuiPreviewPaginationComponent_keydown_arrowLeft_prevent_HostBindingHandler() {
        return ctx.onArrowClick(-1);
      }, false, i0.ɵɵresolveDocument);
    }
  },
  inputs: {
    length: "length",
    index: "index"
  },
  outputs: {
    indexChange: "indexChange"
  },
  decls: 2,
  vars: 3,
  consts: [[4, "ngIf"], ["tuiIconButton", "", "tuiPreviewAction", "", "type", "button", 1, "t-arrow_left", 3, "click", "disabled", "icon", "title"], ["tuiIconButton", "", "tuiPreviewAction", "", "type", "button", 1, "t-arrow_right", 3, "click", "disabled", "icon", "title"]],
  template: function TuiPreviewPaginationComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TuiPreviewPaginationComponent_ng_container_0_Template, 4, 8, "ng-container", 0);
      i0.ɵɵpipe(1, "async");
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 1, ctx.texts$));
    }
  },
  dependencies: [i1.TuiButtonComponent, i3.NgIf, TuiPreviewActionDirective, i3.AsyncPipe],
  styles: ["[_nghost-%COMP%]{border-radius:1rem;background:rgba(104,104,104,.96);color:var(--tui-text-01-night);font:var(--tui-font-text-s);display:flex;justify-content:space-between;align-items:center;width:6.25rem}.t-arrow_left[_ngcontent-%COMP%]{border-top-right-radius:0;border-bottom-right-radius:0}.t-arrow_right[_ngcontent-%COMP%]{border-top-left-radius:0;border-bottom-left-radius:0}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPreviewPaginationComponent, [{
    type: Component,
    args: [{
      selector: 'tui-preview-pagination',
      templateUrl: './preview-pagination.template.html',
      styleUrls: ['./preview-pagination.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_PREVIEW_ICONS]
      }]
    }, {
      type: i4.Observable,
      decorators: [{
        type: Inject,
        args: [TUI_PAGINATION_TEXTS]
      }]
    }];
  }, {
    length: [{
      type: Input
    }],
    index: [{
      type: Input
    }],
    indexChange: [{
      type: Output
    }],
    onArrowClick: [{
      type: HostListener,
      args: ['document:keydown.arrowRight.prevent', ['1']]
    }, {
      type: HostListener,
      args: ['document:keydown.arrowLeft.prevent', ['-1']]
    }]
  });
})();
const STEP = 0.5;
class TuiPreviewZoomComponent {
  constructor(icons, zoomTexts$) {
    this.icons = icons;
    this.zoomTexts$ = zoomTexts$;
    this.min = 0.5;
    this.max = 2;
    this.value = 1;
    this.valueChange = new EventEmitter();
    this.reset = new EventEmitter();
    this.hintShow$ = this.valueChange.pipe(switchMap(() => merge(of(true), timer(1000).pipe(map(ALWAYS_FALSE_HANDLER)))), startWith(false));
  }
  get leftButtonDisabled() {
    return this.value === this.min;
  }
  get rightButtonDisabled() {
    return this.value === this.max;
  }
  get collapseVisible() {
    return this.value > this.min;
  }
  onModelChange(value) {
    const clamped = tuiClamp(value, this.min, this.max);
    if (clamped === this.value) {
      return;
    }
    this.value = clamped;
    this.valueChange.emit(clamped);
  }
  onReset() {
    this.reset.emit();
  }
  onMinus() {
    this.onModelChange(this.value - STEP);
  }
  onPlus() {
    this.onModelChange(this.value < 1 ? 1 : this.value + STEP);
  }
}
TuiPreviewZoomComponent.ɵfac = function TuiPreviewZoomComponent_Factory(t) {
  return new (t || TuiPreviewZoomComponent)(i0.ɵɵdirectiveInject(TUI_PREVIEW_ICONS), i0.ɵɵdirectiveInject(TUI_PREVIEW_ZOOM_TEXTS));
};
TuiPreviewZoomComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiPreviewZoomComponent,
  selectors: [["tui-preview-zoom"]],
  inputs: {
    min: "min",
    max: "max",
    value: "value"
  },
  outputs: {
    valueChange: "valueChange",
    reset: "reset"
  },
  decls: 2,
  vars: 3,
  consts: [["hint", ""], [4, "ngIf"], [1, "t-zoom"], ["tuiIconButton", "", "tuiPreviewAction", "", "type", "button", 1, "t-sign_minus", 3, "click", "disabled", "icon", "title"], ["tuiSliderThumbLabel", ""], ["tuiHintAppearance", "onDark", "tuiHintDirection", "top-right", 3, "tuiHint", "tuiHintManual"], ["step", "any", "tuiSlider", "", "type", "range", 1, "t-slider", 3, "ngModelChange", "max", "min", "ngModel"], ["tuiIconButton", "", "tuiPreviewAction", "", "type", "button", 1, "t-sign_plus", 3, "click", "disabled", "icon", "title"], ["tuiHintAppearance", "onDark", "tuiHintDescribe", "", "tuiHintDirection", "top-right", "tuiIconButton", "", "tuiPreviewAction", "", "type", "button", 1, "t-reset-button", 3, "click", "icon", "tuiHint"]],
  template: function TuiPreviewZoomComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TuiPreviewZoomComponent_ng_container_0_Template, 11, 17, "ng-container", 1);
      i0.ɵɵpipe(1, "async");
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 1, ctx.zoomTexts$));
    }
  },
  dependencies: [i1.TuiButtonComponent, i2.TuiSliderThumbLabelComponent, i2.TuiSliderComponent, i3.NgIf, TuiPreviewActionDirective, i1.TuiHintDirective, i1.TuiHintDriverDirective, i1.TuiHintHoverDirective, i1.TuiHintPositionDirective, i1.TuiHintManualDirective, i5.RangeValueAccessor, i5.DefaultValueAccessor, i5.NgControlStatus, i5.NgModel, i1.TuiHintDescribeDirective, i3.AsyncPipe, i3.PercentPipe],
  styles: ["[_nghost-%COMP%]{position:relative;display:flex}.t-zoom[_ngcontent-%COMP%]{border-radius:1rem;background:rgba(104,104,104,.96);color:var(--tui-text-01-night);font:var(--tui-font-text-s);display:flex;justify-content:space-between;align-items:center;width:12rem}.t-slider[_ngcontent-%COMP%]{width:7.5rem}.t-sign_minus[_ngcontent-%COMP%]{border-top-right-radius:0;border-bottom-right-radius:0}.t-sign_plus[_ngcontent-%COMP%]{border-top-left-radius:0;border-bottom-left-radius:0}.t-invisible[_ngcontent-%COMP%]{visibility:hidden}.t-reset-button[_ngcontent-%COMP%]{margin-left:.3125rem}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPreviewZoomComponent, [{
    type: Component,
    args: [{
      selector: 'tui-preview-zoom',
      templateUrl: './preview-zoom.template.html',
      styleUrls: ['./preview-zoom.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_PREVIEW_ICONS]
      }]
    }, {
      type: i4.Observable,
      decorators: [{
        type: Inject,
        args: [TUI_PREVIEW_ZOOM_TEXTS]
      }]
    }];
  }, {
    min: [{
      type: Input
    }],
    max: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    valueChange: [{
      type: Output
    }],
    reset: [{
      type: Output
    }]
  });
})();
const INITIAL_SCALE_COEF = 0.8;
const EMPTY_COORDINATES = [0, 0];
const ROTATION_ANGLE = 90;
class TuiPreviewComponent {
  constructor(el, icons, texts$) {
    this.el = el;
    this.icons = icons;
    this.texts$ = texts$;
    this.zoomable = true;
    this.rotatable = false;
    this.minZoom = 1;
    this.width = 0;
    this.height = 0;
    this.zoom$ = new BehaviorSubject(this.minZoom);
    this.rotation$ = new BehaviorSubject(0);
    this.coordinates$ = new BehaviorSubject(EMPTY_COORDINATES);
    this.transitioned$ = merge(tuiDragAndDropFrom(this.el.nativeElement).pipe(map(({
      stage
    }) => stage !== TuiDragStage.Continues)), tuiTypedFromEvent(this.el.nativeElement, 'touchmove', {
      passive: true
    }).pipe(map(ALWAYS_FALSE_HANDLER)), tuiTypedFromEvent(this.el.nativeElement, 'wheel', {
      passive: true
    }).pipe(map(ALWAYS_FALSE_HANDLER)));
    this.cursor$ = tuiDragAndDropFrom(this.el.nativeElement).pipe(map(({
      stage
    }) => stage === TuiDragStage.Continues ? 'grabbing' : 'initial'), startWith('initial'));
    this.wrapperTransform$ = combineLatest([this.coordinates$.pipe(map(([x, y]) => `${tuiPx(x)}, ${tuiPx(y)}`)), this.zoom$, this.rotation$]).pipe(map(([translate, zoom, rotation]) => `translate(${translate}) scale(${zoom}) rotate(${rotation}deg)`));
  }
  rotate() {
    this.rotation$.next(this.rotation$.value - ROTATION_ANGLE);
  }
  onPan(delta) {
    this.coordinates$.next(this.getGuardedCoordinates(this.coordinates$.value[0] + delta[0], this.coordinates$.value[1] + delta[1]));
  }
  onMutation(contentWrapper) {
    const {
      clientWidth,
      clientHeight
    } = contentWrapper;
    this.refresh(clientWidth, clientHeight);
  }
  onZoom({
    clientX,
    clientY,
    delta
  }) {
    if (this.zoomable) {
      this.processZoom(clientX, clientY, delta);
    }
  }
  onResize(contentResizeEntries) {
    if (contentResizeEntries.length === 0) {
      return;
    }
    const {
      width,
      height
    } = contentResizeEntries[0].contentRect;
    this.refresh(width, height);
  }
  reset() {
    this.zoom$.next(this.minZoom);
    this.coordinates$.next(EMPTY_COORDINATES);
  }
  setZoom(zoom) {
    this.zoom$.next(zoom);
    const [x, y] = this.coordinates$.value;
    this.coordinates$.next(this.getGuardedCoordinates(x, y));
  }
  get offsets() {
    const offsetX = (this.zoom$.value - this.minZoom) * this.width / 2;
    const offsetY = (this.zoom$.value - this.minZoom) * this.height / 2;
    return {
      offsetX,
      offsetY
    };
  }
  calculateMinZoom(contentHeight, contentWidth, boxHeight, boxWidth) {
    const bigSize = contentHeight > boxHeight * INITIAL_SCALE_COEF || contentWidth > boxWidth * INITIAL_SCALE_COEF;
    const {
      clientHeight,
      clientWidth
    } = this.el.nativeElement;
    return bigSize ? tuiRound(Math.min(clientHeight * INITIAL_SCALE_COEF / contentHeight, clientWidth * INITIAL_SCALE_COEF / contentWidth), 2) : 1;
  }
  refresh(width, height) {
    this.width = width;
    this.height = height;
    this.minZoom = this.calculateMinZoom(height, width, this.el.nativeElement.clientHeight, this.el.nativeElement.clientWidth);
    this.zoom$.next(this.minZoom);
    this.coordinates$.next(EMPTY_COORDINATES);
    this.rotation$.next(0);
  }
  processZoom(clientX, clientY, delta) {
    const oldScale = this.zoom$.value;
    const newScale = tuiClamp(oldScale + delta, this.minZoom, 2);
    const center = this.getScaleCenter({
      clientX,
      clientY
    }, this.coordinates$.value, this.zoom$.value);
    const moveX = center[0] * oldScale - center[0] * newScale;
    const moveY = center[1] * oldScale - center[1] * newScale;
    this.zoom$.next(newScale);
    this.coordinates$.next(this.getGuardedCoordinates(this.coordinates$.value[0] + moveX, this.coordinates$.value[1] + moveY));
  }
  getGuardedCoordinates(x, y) {
    const {
      offsetX,
      offsetY
    } = this.offsets;
    return [tuiClamp(x, -offsetX, offsetX), tuiClamp(y, -offsetY, offsetY)];
  }
  getScaleCenter({
    clientX,
    clientY
  }, [x, y], scale) {
    return [(clientX - x - this.el.nativeElement.offsetWidth / 2) / scale, (clientY - y - this.el.nativeElement.offsetHeight / 2) / scale];
  }
}
TuiPreviewComponent.ɵfac = function TuiPreviewComponent_Factory(t) {
  return new (t || TuiPreviewComponent)(i0.ɵɵdirectiveInject(ElementRef), i0.ɵɵdirectiveInject(TUI_PREVIEW_ICONS), i0.ɵɵdirectiveInject(TUI_PREVIEW_TEXTS));
};
TuiPreviewComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiPreviewComponent,
  selectors: [["tui-preview"]],
  inputs: {
    zoomable: "zoomable",
    rotatable: "rotatable"
  },
  ngContentSelectors: _c1,
  decls: 2,
  vars: 3,
  consts: [["contentWrapper", ""], [4, "ngIf"], ["attributeFilter", "src", "characterData", "", "childList", "", "subtree", "", 1, "t-wrapper", 3, "tuiPan", "tuiResize", "tuiZoom", "waMutationObserver"], [1, "t-header"], [1, "t-title"], [1, "t-actions"], [1, "t-footer"], ["tuiHintAppearance", "onDark", "tuiHintDescribe", "", "tuiHintDirection", "top-right", "tuiIconButton", "", "tuiPreviewAction", "", "type", "button", "class", "t-rotate-button", 3, "icon", "tuiHint", "click", 4, "ngIf"], [3, "min", "value", "reset", "valueChange", 4, "ngIf"], ["tuiHintAppearance", "onDark", "tuiHintDescribe", "", "tuiHintDirection", "top-right", "tuiIconButton", "", "tuiPreviewAction", "", "type", "button", 1, "t-rotate-button", 3, "click", "icon", "tuiHint"], [3, "reset", "valueChange", "min", "value"]],
  template: function TuiPreviewComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef(_c0);
      i0.ɵɵtemplate(0, TuiPreviewComponent_ng_container_0_Template, 16, 16, "ng-container", 1);
      i0.ɵɵpipe(1, "async");
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 1, ctx.texts$));
    }
  },
  dependencies: [i1.TuiButtonComponent, TuiPreviewZoomComponent, i3.NgIf, i4$1.TuiPanDirective, i4$1.TuiResizeDirective, i4$1.TuiZoomDirective, i5$1.MutationObserverDirective, i1.TuiHintDescribeDirective, TuiPreviewActionDirective, i1.TuiHintDirective, i1.TuiHintDriverDirective, i1.TuiHintHoverDirective, i1.TuiHintPositionDirective, i3.AsyncPipe],
  styles: ["[_nghost-%COMP%]{position:relative;display:flex;justify-content:center;align-items:center;width:100%;height:100%;-webkit-user-select:none;-moz-user-select:none;user-select:none}.t-header[_ngcontent-%COMP%]{position:fixed;top:1rem;display:flex;width:100%;padding:0 1rem;box-sizing:border-box}.t-footer[_ngcontent-%COMP%]{position:absolute;bottom:1rem;display:flex;width:100%;padding:0 1rem;box-sizing:border-box;justify-content:center}.t-actions[_ngcontent-%COMP%]{display:flex;flex:1;justify-content:flex-end}.t-actions[_ngcontent-%COMP%]    >*{margin-left:.625rem}.t-rotate-button[_ngcontent-%COMP%]{margin-right:.3125rem}.t-title[_ngcontent-%COMP%]{flex:1}tui-root._mobile[_nghost-%COMP%]   .t-title[_ngcontent-%COMP%], tui-root._mobile   [_nghost-%COMP%]   .t-title[_ngcontent-%COMP%]{display:none}.t-not-interactive-content[_ngcontent-%COMP%]    >*{pointer-events:none}.t-wrapper[_ngcontent-%COMP%]{will-change:transform}.t-transitive[_ngcontent-%COMP%]{transition-duration:.3s}"],
  data: {
    animation: [tuiSlideInTop]
  },
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPreviewComponent, [{
    type: Component,
    args: [{
      selector: 'tui-preview',
      templateUrl: './preview.template.html',
      styleUrls: ['./preview.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      animations: [tuiSlideInTop]
    }]
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_PREVIEW_ICONS]
      }]
    }, {
      type: i4.Observable,
      decorators: [{
        type: Inject,
        args: [TUI_PREVIEW_TEXTS]
      }]
    }];
  }, {
    zoomable: [{
      type: Input
    }],
    rotatable: [{
      type: Input
    }]
  });
})();
class TuiPreviewActionModule {}
TuiPreviewActionModule.ɵfac = function TuiPreviewActionModule_Factory(t) {
  return new (t || TuiPreviewActionModule)();
};
TuiPreviewActionModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiPreviewActionModule
});
TuiPreviewActionModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPreviewActionModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiPreviewActionDirective],
      exports: [TuiPreviewActionDirective]
    }]
  }], null, null);
})();
class TuiPreviewTitleComponent {}
TuiPreviewTitleComponent.ɵfac = function TuiPreviewTitleComponent_Factory(t) {
  return new (t || TuiPreviewTitleComponent)();
};
TuiPreviewTitleComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiPreviewTitleComponent,
  selectors: [["tui-preview-title"]],
  ngContentSelectors: _c2,
  decls: 1,
  vars: 0,
  template: function TuiPreviewTitleComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  styles: ["[_nghost-%COMP%]{border-radius:1rem;background:rgba(104,104,104,.96);color:var(--tui-text-01-night);font:var(--tui-font-text-s);display:inline-block;padding:.375rem .75rem}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPreviewTitleComponent, [{
    type: Component,
    args: [{
      selector: 'tui-preview-title',
      template: `
        <ng-content></ng-content>
    `,
      styleUrls: ['./preview-title.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class TuiPreviewModule {}
TuiPreviewModule.ɵfac = function TuiPreviewModule_Factory(t) {
  return new (t || TuiPreviewModule)();
};
TuiPreviewModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiPreviewModule
});
TuiPreviewModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [tuiAsDialog(TuiPreviewDialogService)],
  imports: [[CommonModule, PolymorpheusModule, MutationObserverModule, TuiButtonModule, FormsModule, TuiSliderModule, TuiHintModule, TuiDragModule, TuiResizeModule, TuiSvgModule, TuiLoaderModule, TuiPanModule, TuiZoomModule, TuiPreviewActionModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPreviewModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, PolymorpheusModule, MutationObserverModule, TuiButtonModule, FormsModule, TuiSliderModule, TuiHintModule, TuiDragModule, TuiResizeModule, TuiSvgModule, TuiLoaderModule, TuiPanModule, TuiZoomModule, TuiPreviewActionModule],
      declarations: [TuiPreviewComponent, TuiPreviewTitleComponent, TuiPreviewPaginationComponent, TuiPreviewZoomComponent],
      providers: [tuiAsDialog(TuiPreviewDialogService)],
      exports: [TuiPreviewComponent, TuiPreviewActionDirective, TuiPreviewTitleComponent, TuiPreviewPaginationComponent, TuiPreviewZoomComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiPreviewActionDirective, TuiPreviewActionModule, TuiPreviewComponent, TuiPreviewModule, TuiPreviewPaginationComponent, TuiPreviewTitleComponent, TuiPreviewZoomComponent };
